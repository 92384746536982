import React, { Component } from "react";
import styled from "styled-components";
import "./HamburgerMenu.scss";
import Menu from "../Menu/Menu";
import about from "../../_asset/images/common/about-icon.png";
import download from "../../_asset/images/common/download-icon.png";
import menu from "../../_asset/images/common/menu-icon.png";
import pdf from "../../Common/Data/WorkData/toastcanvas_portfolio.pdf";

class HamburgerMenu extends Component {
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
    };

    buttonClick = () => {
        // 모달 보이고 감추기 Toggle
        this.setState((prevState) => ({
            isDialogOpen: !prevState.isDialogOpen,
        }));
    };

    render() {
        const { isLogoBlack } = this.props;
        const headerClassName = isLogoBlack ? " Black" : " White";

        return (
            <div className="ButtonContainer">
                <button className={"btn about disable" + headerClassName}>
                    <a href="/about">
                        <div className="menubtn disable">about</div>
                        <svg className="menuIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                            <path
                                fillRule="evenodd"
                                d="M12.7,5.2l-1.4,2c-0.1,0.1-0.2,0.2-0.4,0.2H7.2v4.7c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V7.4H1.5C1.2,7.4,1,7.2,1,6.9V2.9c0-0.3,0.2-0.5,0.5-0.5h4.7V1.7c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.7h3.8c0.2,0,0.3,0.1,0.4,0.2l1.4,2C12.8,4.8,12.8,5,12.7,5.2z M10.6,3.4H2v3.1h8.7l1.1-1.6C11.7,4.9,10.6,3.4,10.6,3.4z"
                            />
                        </svg>
                    </a>
                </button>
                <button className={"btn" + headerClassName}>
                    <a href={pdf} target="_blank">
                        <div className="menubtn">download</div>
                        <svg className="menuIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                            <path fillRule="evenodd" d="M2,13v-1h9v1H2z M7,10H6.3l0,0l0,0H6V9.8L2.7,6.5l0.7-0.7L6,8.3V1h1v7.3l2.5-2.5l0.7,0.7L7,9.7V10z" />
                        </svg>
                    </a>
                </button>
                <button onClick={this.buttonClick} className={"btn" + headerClassName}>
                    <div className="menubtn">menu</div>
                    <svg className="menuIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <path fillRule="evenodd" d="M2,4V3h10v1H2z M10,8H2V7h8V8z M7,12H2v-1h5V12z" />
                    </svg>
                    <Menu open={this.state.isDialogOpen} />
                </button>
            </div>
        );
    }
}

export default HamburgerMenu;
