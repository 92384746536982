import React, { Component } from 'react';
import './HeaderMain.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';


class Header extends Component {
    render() {
        return (
            <header className="HeaderMain">
                <HeaderLogo></HeaderLogo>
                <HamburgerMenu></HamburgerMenu>
          </header>
        );
    }
}

export default Header;