import React, { Component, useState } from "react";
import Header from "../../Common/HeaderMain/HeaderMain";
import Footer from "../../Common/FooterMain/FooterMain";
import Category from "../../Experience/ExMain/Category/Category";
import Grid from "../../Experience/ExMain/Grid/Grid";

const RouterExperience = () => {
    const [load, setLoad] = useState(false);

    const handleLoad = () => {
        setLoad(true);
    };

    return (
        <div>
            <Header></Header>
            <Category></Category>
            <Grid onLoad={handleLoad}></Grid>
            {load && <Footer />}
        </div>
    );
};

export default RouterExperience;
