import React, { Component } from "react";
import "./Style/SecondPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import video from "../../_asset/lottie/toastcanvas.mp4";
// import bgvideo from "../../_asset/lottie/second_bg.mp4";
// import footerAnimationMobile from '../../_asset/animation/landing_07_mobile.json';

// api fullpage.js 문서 참고 : https://github.com/alvarotrigo/fullPage.js#lazy-loading
// data-autoplay =  Lazy loading 형태로 Section에 진입해야지만 재싱되도록 설정
// data-keepplaying = section이동시 자동으로 멈추는 Default 값을 끄는 옵션 (지속적으로 Background 에서 재생)
// data-src = 소스 파일을 Lazy loading 처리하는 옵션

class SecondPage extends Component {
    render() {
        return (
            <div className="SecondPage">
                <div className="video-wrap">
                    {/* <video className='bg-background-video'>
						<source data-src ={bgvideo} type='video/mp4' />
					</video> */}
                    <div>
                        <video className="animation-video" loop data-autoplay muted={true}>
                            <source data-src={video} type="video/mp4" />
                        </video>
                    </div>
                </div>
                <div className="Link">
                    <div className="LinkClickContainer">
                        <div className="LinkClickText">
                            <a href="/about" style={{ pointerEvents: "none", opacity: 0.3 }}>
                                Click
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SecondPage;
