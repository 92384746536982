import React, { Component } from "react";
import "./Category.scss";

class Category extends Component {
  render() {
    return (
      <ul className="experience__category  ">
        {/* <li className="experience__category__name selected">
                  <div className="mask-text"><span>ALL</span></div>
                </li>
                <li className="experience__category__name">
                  <div className="mask-text"><span>BX</span></div>
                </li>
                <li className="experience__category__name">
                  <div className="mask-text"><span>UX</span></div>
                </li> */}
      </ul>
    );
  }
}

export default Category;
