import React, { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./HeaderLogo.scss";

class HeaderLogo extends React.Component {
    render() {
        const { isLogoBlack } = this.props;
        const headerClassName = isLogoBlack ? "HeaderLogo Black" : "HeaderLogo White";
        return (
            <div className="HeaderLogoMain">
                <a href="/">
                    <div className={headerClassName}> TOASTCANVAS</div>{" "}
                </a>
            </div>
        );
    }
}

export default HeaderLogo;
