import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Contact.scss";

class Contact extends Component {
    handleClickCopy() {
        toast.success('Copied!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
    }
    render() {
        return (
            <div className="ContactContainer">
                <div className="ContactMain">
                    <div className="ContactList Contact">
                        <div className="ContactLeftBox">
                            <div className="ContactLeftTitle">Contact</div>
                        </div>
                        <div className="ContactRightBox">
                            <div className="ContactRightTitle">
                                <span>
                                    <CopyToClipboard
                                        text="info.toastcanvas@gmail.com"
                                        data-tip="click to copy"
                                        onCopy={this.handleClickCopy}
                                    >
                                        <span>info.toastcanvas@gmail.com</span>
                                    </CopyToClipboard>
                                    <ReactTooltip />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ContactList Support">
                        <div className="ContactLeftBox">
                            <div className="ContactLeftTitle">Support</div>
                        </div>
                        <div className="ContactRightBox">
                            <div className="ContactRightTitle">
                                <CopyToClipboard
                                    text="info.toastcanvas@gmail.com"
                                    data-tip="click to copy"
                                    onCopy={this.handleClickCopy}
                                >
                                    <span>info.toastcanvas@gmail.com</span>
                                </CopyToClipboard>
                                <ReactTooltip />
                            </div>
                        </div>
                    </div>
                    <div className="ContactBottomList">
                        <div className="ContactList Address">
                            <div className="ContactLeftBox">
                                <div className="ContactLeftTitle">Address</div>
                            </div>
                            <div className="ContactRightBox">
                                <div className="ContactRightTitle">
                                    <CopyToClipboard
                                        text="17-10, Dosan-daero 8-gil, Gangnam-gu, Seoul, Korea"
                                        data-tip="click to copy"
                                        onCopy={this.handleClickCopy}
                                    >
                                        <span>
                                            17-10, Dosan-daero <br />
                                            8-gil, Gangnam-gu, <br />
                                            Seoul, Korea
                                        </span>
                                    </CopyToClipboard>
                                    <ReactTooltip />
                                </div>
                            </div>
                        </div>
                        <div className="ContactList Follow">
                            <div className="ContactLeftBox">
                                <div className="ContactLeftTitle">Follow</div>
                            </div>
                            <div className="ContactRightBox">
                                <div className="ContactRightTitle Sns">
                                    <a href="">Facebook</a>
                                    <br />
                                    <a href="">Instagram</a>
                                    <br />
                                    <a href="">Vimeo</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer theme="dark"/>
            </div>
        );
    }
}

export default Contact;
