import React, { Component } from "react";
import Lottie from "react-lottie";
import "./Style/ThirdPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import LinkClick from "../../Common/LinkClick/LinkClick";
import Animation from "../../_asset/lottie/third.json";

const lottieOptions = {
    loop: true,
    autoplay: true,
    renderer: "svg",
    animationData: Animation,
    rendererSettings: {
        className: "svgContainer",
        preserveAspectRatio: "xMidYMid meet",
        progressiveLoad: true, // is meant to add elements to the dom progressively instead of loading all at once, but the json needs to be fully loaded.
    },
};
// console.log(this.props.testPage);
class ThirdPage extends Component {
    render() {
        return (
            <section
                className="ThirdPage"
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                }}
            >
                <div className="Link">
                    <div className="LinkClickContainer">
                        <div className="LinkClickText">
                            <a href="/about" style={{ pointerEvents: "none", opacity: 0.3 }}>
                                Click
                            </a>
                        </div>
                    </div>
                </div>
                <Lottie options={lottieOptions} />
            </section>
        );
    }
}

export default ThirdPage;
