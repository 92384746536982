import React, { Component } from "react";
import "./Style/FirstPage.scss";
import "../../Common/LinkClick/LinkClick.scss";
import CrossGrid from "../../Common/MainBackGround/MainBack1/CrossGrid";
class FirstPage extends Component {
    render() {
        return (
            <div className="FirstPage" style={{ width: "100%", height: "100%", color: "#FFF", background: "#e7304d" }}>
                {/* <CrossGrid className="header2" /> */}
                <div className="main-slider__header">
                    <div className="FirstPageTitle">
                        <a href="/Works">
                            Hello. We are TOASTCANVAS
                            <br />
                            We Share Our Experience
                            <br />
                            with you
                        </a>
                    </div>
                </div>
                <div className="Link">
                    <div className="LinkClickContainer">
                        <div className="LinkClickText">
                            <a href="/works">Click</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FirstPage;
