import React, { Component } from 'react';
import './style/ReadyPopup.scss';
import { Dialog } from "@material-ui/core";
import { CopyToClipboard } from 'react-copy-to-clipboard'

class ReadyPopup extends Component {
    state = {
        isDialogOpen: false,
        isOneDialogOpen: false,
        index: 1
    }

    handleDialogClose = () => {
        this.setState({
            isDialogOpen: false,
            isOneDialogOpen: false
        })
    }
    
    buttonClick = (index) => {
        if(index === 1){
            this.props.onClickPopupNext();
        } 
        // this.setState({
        //     index,
        // }, () => this.setState({isDialogOpen: true}))
    }
    render() {
        const { 
            onClose,
            open,
            noButton,
            dialogType,
            onButtonClick,
            index
        } = this.props;
        return (
            <Dialog fullWidth={this.props.fullWidth} maxWidth={"lg"} open={open} onClose={onClose}>
                <div className='ReadyPopupContainer'>
                    <div className='ReadyPopupMain'>
                        <div className='ReadyPopupTextBox'>
                            <div className='ReadyPopupTitle'>
                                준비중입니다.
                            </div>
                        </div>
                        <button onClick={onClose} className='ReadyPopupCheck'>
                            확인
                        </button>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default ReadyPopup;