import React, { Component } from 'react';
import './LinkClick.scss'

class LinkClick extends Component {
    render() {
        return (
            <div className='LinkClickContainer'>
                <div className='LinkClickText'>
                    <a href='/works'>Click</a>
                </div>
            </div>
        );
    }
}

export default LinkClick;