import { configureStore } from "@reduxjs/toolkit";

import thumbnailReducer from "src/Experience/ExMain/Grid/GetThumbnail/thumbnailSlice";
import videosDataReducer from './slice/videoDataSlice';

export const store = configureStore({
    reducer: {
        // thumbnail: thumbnailReducer,
        videosData: videosDataReducer
    },
})