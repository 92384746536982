import React, { Component } from 'react';
import './About.scss';
import brand1 from '../../_asset/images/About/logo-01@2x.png'
import brand2 from '../../_asset/images/About/logo-02@2x.png'
import brand3 from '../../_asset/images/About/logo-03@2x.png'
import brand4 from '../../_asset/images/About/logo-04@2x.png'
import brand5 from '../../_asset/images/About/logo-05@2x.png'
import brand6 from '../../_asset/images/About/logo-06@2x.png'
import brand7 from '../../_asset/images/About/logo-07@2x.png'
import brand8 from '../../_asset/images/About/logo-08@2x.png'
import brand9 from '../../_asset/images/About/logo-09@2x.png'
import brand10 from '../../_asset/images/About/logo-10@2x.png'

class About extends Component {
    render() {
        return (
            <div className='AboutContainer'>
                <div className='AboutMain'>
                    <div className='AboutList About'>
                        <div className='AboutLeftBox'>
                            <div className='AboutLeftTitle'>About</div>
                        </div>
                        <div className='AboutRightBox'>
                            <div className='AboutRightTitle'>
                                루크는 창의적으로 생각하고 이성적으로 정리하며 논리적으로 설계합니다.  <br />
                                루크에서는 제약없는 상상과 합리적인 사고 과정, 그리고 최적화된 결과물이 보여집니다. 
                            </div>
                            <div className='AboutRightSubBox'>
                                <div className='AboutRightSub a'>
                                    <p>
                                        우리는 고객의 경험을 디자인합니다. 총체적인 브랜딩 경험을 제공합니다. 
                                        고객의 디자인 정체성을 뚜렷하게 나타내며 브랜드에 힘을 실을 수 있게 도와 드립니다. 
                                        기업의 브랜딩과 웹&amp;앱 모바일 디자인과 개발을 함께 진행하면서 브랜드 가치를 디자인으로 그려 냅니다.
                                    </p>
                                    <p>
                                        루크는 우리만의 정체성을 가지고 있지 않습니다. 
                                        루크는 고객과 대화하는 순간부터 고객 그 자신이 되기 때문입니다.
                                        고객사의 니즈를 파악하고, 시대의 흐름에 맞게 방향성을 제시하며, 구체적인 디자인 전략이 창출될 수 있도록 돕습니다. 
                                    </p>
                                    <p>
                                        Looc는 아무것도 아니지만 모든 것입니다. 
                                        '시각화' 작업의 본질에 다가가려는 움직임 자체입니다.  
                                        브랜드 이미지를 구성하는 여러 매체를 아우르며 시각 디자인 작업물을 고도화시켜 나갑니다. 
                                        루크와 함께라면 누구라도 브랜드 정체성을 발견하고, 과거와 미래를 새롭게 정의하며, 보다 부드럽게 혹은 보다 날카롭게 다듬어갈 수 있습니다. 
                                    </p>
                                    <p>
                                        루크는 생각합니다. 루크는 당신의 생각을 걷습니다.
                                    </p>
                                </div>
                                {/* <div className='AboutRightSub b'>
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔터테인먼트 공간을 구현하는 “Digital Theme Park” 사업과 HIGH-END 부동산 및 브랜드를 대상으로 혁신적인 공간 경험을 제공하는 “Digital eXperience Design” 사업을 수행하고 있습니다.
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='AboutList People'>
                        <div className='AboutLeftBox'>
                            <div className='AboutLeftTitle'>People</div>
                        </div>
                        <div className='AboutRightBox'>
                            <div className='AboutRightTitle'>
                                루크에서는 다양한 경험을 가진 사람들이 모여, 열린 마음으로 세상을 품고 바라봅니다. <br />
                                다채로운 시각들은 나를, 그리고 우리를 크게 성장시켜 나가는 숨결이 됩니다. 
                            </div>
                            <div className='AboutRightSubBox'>
                                <div className='AboutRightSub a'>
                                    <p>
                                        루크에서는 기획&amp;브랜딩, 마케팅&amp;홍보, UI/UX디자인, 개발 등 다양한 직군의 사람들이 모여서 시너지를 내고 있습니다.
                                        우리와 함께 걷는 사람들은 주체적인 탐구자이며, 항상 한계를 넘어 새롭게 시도해 보도록 장려합니다.
                                    </p>
                                    <p>
                                        우리는 모두가 서로의 아이디어에 집중합니다.
                                        문제 상황에서 모든 가능한 솔루션을 풀어내 보며, 해결 과정에서 새로운 아이디어와 기회를 창출해 냅니다. 
                                        문제 해결을 위한 대화의 과정은 서로를 아끼고 신뢰하며 존중하는 문화 그 자체입니다. 
                                    </p>
                                    <p>
                                        루크에는 성공과 실패가 없습니다. 다른 누구도 우리의 실패와 성공을 판단하지 않습니다. 
                                        모든 도전은 무게를 달지 않아도 똑같이 의미와 가치가 있고, 루크의 자산이 되며 역사가 되기 때문입니다.  
                                    </p>
                                    <p>
                                        루크는 성장마인셋'을 지향하며 당신의 개인적인 성장을 지지하고 지원합니다. 
                                        당신의 개인적인 성장에 꾸준한 들숨이 되며, 당신이 자라가는 공간 속의 날숨이고자 합니다. 
                                    </p>
                                    <p>
                                        루크는 바랍니다. 루크는 당신의 바람입니다.
                                    </p>
                                </div>
                                {/* <div className='AboutRightSub b'>
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔터테인먼트 공간을 구현하는 “Digital Theme Park” 사업과 HIGH-END 부동산 및 브랜드를 대상으로 혁신적인 공간 경험을 제공하는 “Digital eXperience Design” 사업을 수행하고 있습니다.
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='AboutList Collab'>
                        <div className='AboutLeftBox'>
                            <div className='AboutLeftTitle'>Collaborators</div>
                        </div>
                        <div className='AboutRightBox'>
                            <div className='AboutRightTitle'>
                                루크는 세상이 필요로 하는 결과물을 위해 경험의 가치를 소중히 생각하는 분들과 손을 잡습니다. <br />
                                최고가 당연해지는 최적화를 추구하며 세상에 이로운 방식으로 우리의 더 우리됨을 만들어 갑니다.
                            </div>
                            <div className='AboutRightSubBox'>
                                <div className='AboutRightSub a'>
                                    <p>
                                        루크는 다양한 비즈니스를 운영했던 경험을 바탕으로 자체 플랫폼과 브랜드를 개발, 런칭해 왔습니다. 
                                        유니크한 브랜드들이 자기다움을 형성해 가도록 도우면서 에이전시로 발전할 수 있었고, 
                                        지금은 자체 브랜드를 넓혀 나가는 과정에서 총체적 브랜드 메이커로서 앞으로 제약 없는 도약을 꿈꾸고 있습니다. 
                                    </p>
                                    <p>
                                        다양한 산업군의 프로젝트 협연을 모색하고, 국내외 유수 기관들의 디자인 솔루션을 제시하며 통합적인 브랜딩 전략을 세워 나갑니다. 
                                        브랜드의 디자인이 창조되는 고민의 과정을 대중과 지속적으로 공유하며 그 과정에서 협력적인 브랜드 가치에 대한 인식이 높아져 갑니다. 
                                    </p>
                                    <p>
                                        전 세계 디자이너와 업계 전문가들이 주목할 만한 완성도 있는 프로젝트를 지향하면서 
                                        디자인 작업을 고도화하고 디자인 산업 자체의 가치를 높여 나갑니다. 
                                        '원래 그렇다'는 디자인 컨설팅 업계의 관습에 도전장을 던지며, 바위에 끊임없이 부딪쳐 그것을 깎아내는 물방울이 되고 샘물이 됩니다. 
                                    </p>
                                    <p>
                                        타 디자인 회사, 개발사와 협업 프로젝트에 참여하는 경험을 통해 바깥으로 뻗어 나가는 동시에 내부적으로 깊어지며, 
                                        회사의 경계가 허물어지고 우리의 비즈니스는 디자인 공유 커뮤니티가 됩니다. 
                                        든든한 파트너들과 함께 에이전시로 한계를 넘어 글로벌 브랜드 시장이라는 바다로 나아가기를 꿈꿉니다.
                                    </p>
                                    <p>
                                        지금까지 손 내밀며 걸어왔던 루크의 역사는 앞으로 걸어갈 미래의 바탕입니다.
                                        브랜드의 본질을 이해할 수 있도록 가이드를 제시하면서 지속 가능한 글로벌 비즈니스가 되려는 모험을 감내하는 것은  
                                        디자인 업계에 오래된 미래를 제시하는 크리에이터 그룹을 완성시켜 갈 것입니다.
                                    </p>
                                    <p>
                                        루크는 기다립니다. 당신과 발맞춤을 기대합니다.
                                    </p>
                                </div>
                                {/* <div className='AboutRightSub b'>
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔터테인먼트 공간을 구현하는 “Digital Theme Park” 사업과 HIGH-END 부동산 및 브랜드를 대상으로 혁신적인 공간 경험을 제공하는 “Digital eXperience Design” 사업을 수행하고 있습니다.
                                    2004년 설립 이래 다양한 디지털 미디어 기술의 변화에 발맞춰 주력사업을 변화시켜 왔던 디스트릭트는 2012년 세계최초 디지털 테마파크인 “LIVE PARK”를 런칭한 이후 특정 주제의 콘텐츠(IP)를 소재로 새로운 개념의 엔
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='AboutList Brand'>
                        <div className='AboutLeftBox'>
                            <div className='AboutLeftTitle'></div>
                        </div>
                        <div className='AboutRightBox'>
                            <div className='AboutBrandImgBox'>
                                <img src={brand1} alt="협력 기업" />
                                <img src={brand2} alt="협력 기업" />
                                <img src={brand3} alt="협력 기업" />
                                <img src={brand4} alt="협력 기업" />
                                <img src={brand5} alt="협력 기업" />
                                <img src={brand6} alt="협력 기업" />
                                <img src={brand7} alt="협력 기업" />
                                <img src={brand8} alt="협력 기업" />
                                <img src={brand9} alt="협력 기업" />
                                <img src={brand10} alt="협력 기업" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default About;