import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/Gfooter.scss";
import icon from "../../../../_asset/images/g9/ico_plusx.png";
import imgmember from "../../../../_asset/images/g9/img_txt_member.png";

const Storytit = styled.div`
  font-size: 30px;
  font-weight: 600;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;
const Storytxt = styled.div`
  color: #555;
  font-size: 14px;
  line-height: 28px;
  margin: 44px 0 0 25px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Icon = styled.div`
  width: 73px;
  margin: 50px 0 0 25px;
  > img {
    width: 100%;
    opacity: ${(props) => (props.trigger ? "1" : "0")};
    transform: ${(props) =>
      props.trigger
        ? "translate3d(0px, 0px, 0px)"
        : "translate3d(0px, 60px, 0px)"};
    transition: opacity 1s linear;
    transition: transform 1s linear;
  }
`;

const Member = styled.div`
  width: 416px;
  margin-left: 30px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    width: 100%;
  }
`;

const Gfooter = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 25000) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gfooter_container">
      <div className="story_inner">
        <Storytit trigger={trigger}>
          "The G9 HAS TAKEN THE FIRST STEP <br></br>
          TOWORD CHANGE. IT WILL BE THE <br></br>
          REPRESNTATIVE E-COMMERCE OF KOREA"
        </Storytit>
        <Storytxt trigger={trigger}>
          G9의 변화는 이제 시작되었습니다. 그리고 앞으로 더 발전해 나갈
          것입니다. 이러한 변화들이 모여 G9가 한국을 <br></br>
          대표하는 쇼핑커머스로 발전하길 기원합니다.
        </Storytxt>
        <Icon trigger={trigger}>
          <img src={icon} alt="plus_ex_icon" />
        </Icon>
      </div>
      <div className="member">
        <div className="inner">
          <Member trigger={trigger}>
            <img src={imgmember} alt="member" />
          </Member>
        </div>
      </div>
    </div>
  );
};

export default Gfooter;
