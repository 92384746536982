import React, { Component } from 'react';
import About from '../../Home/About/About';
import FooterMain from "../../Common/FooterMain/FooterMain";
import HeaderMain from "../../Common/HeaderMain/HeaderMain";

class RouterAbout extends Component {
    render() {
        return (
            <div>
                <HeaderMain />
                <About />
                <FooterMain />
            </div>
        );
    }
}

export default RouterAbout;