import React, { Component } from "react";
import "./MainBack4.scss";
import "../../../Common/LinkClick/LinkClick.scss";

import MenuItem from "./menuItem.js";
import LinkClick from "../../../Common/LinkClick/LinkClickBlack";
import { withRouter } from "react-router-dom";

class MainBack4 extends Component {
	render() {
		return (
			<div className="MainBack4Container">
				<nav className="menu">
					<MenuItem
						className="menuitem"
						filterId="goo-1"
						history={this.props.history}
						text_1="Advertisement"
						text_2="Creativity"></MenuItem>
					<MenuItem
						className="menuitem"
						filterId="goo-2"
						history={this.props.history}
						text_1="Music Video"
						text_2="Story"></MenuItem>
					<MenuItem
						className="menuitem"
						filterId="goo-3"
						history={this.props.history}
						text_1="Motion"
						text_2="Arrangement"></MenuItem>
					<MenuItem
						className="menuitem"
						filterId="goo-4"
						history={this.props.history}
						text_1="Title"
						text_2="Experience"></MenuItem>
					<MenuItem
						className="menuitem"
						filterId="goo-5"
						history={this.props.history}
						text_1="Artwork"
						text_2="Mentality"></MenuItem>
					{/* <MenuItem
						className="menuitem"
						filterId="goo-6"
						history={this.props.history}
						text_1="Development"
						text_2="Arrangement"></MenuItem> */}
				</nav>
				<div className="Link">
					<div className="LinkClickBlackContainer">
						<div className="LinkClickBlackText">
							<a href="/works">Click</a>
						</div>
					</div>
				</div>
				<script src="https://tympanus.net/codrops/adpacks/cda.js"></script>
				<script src="./index.js"></script>
			</div>
		);
	}
	// import React, { Component } from 'react';
	// import './MainBack4.scss';
	// import MenuItem from './menuItem.js';

	// class MainBack4 extends Component {
	//     render() {
	//         return (
	//             <div className='MainBack4Container'>
	//                 <nav className="menu">
	//                     <MenuItem className='menuitem' filterId="goo-1" text_1 = 'Branding' text_2 = 'Mentality'></MenuItem>
	//                     <MenuItem className='menuitem' filterId="goo-2" text_1 = 'UIUX Design' text_2 = 'Experience'></MenuItem>
	//                     <MenuItem className='menuitem' filterId="goo-3" text_1 = 'Marketing' text_2 = 'Reason'></MenuItem>
	//                     <MenuItem className='menuitem' filterId="goo-4" text_1 = 'Motion Artwork' text_2 = 'Story'></MenuItem>
	//                     <MenuItem className='menuitem' filterId="goo-5" text_1 = 'Advertisement' text_2 = 'Creativity'></MenuItem>
	//                     <MenuItem className='menuitem' filterId="goo-6" text_1 = 'Development' text_2 = 'Arrangement'></MenuItem>
	//         </nav>
	//                 <div className='Link'>
	//                     <div className='LinkClickContainer'>
	//                     <div className='LinkClickText'>
	//                         <a href='/works'>Click</a>
	//                     </div>
	//             </div>
	//                 </div>
	//         <script src="https://tympanus.net/codrops/adpacks/cda.js"></script>
	//         <script src="./index.js"></script>
	//             </div>

	//         );
	//     }
}

export default withRouter(MainBack4);
