import React, { useState, useEffect } from "react";
import "./NoticeGrid.scss";
import DisplayData from "../../../Common/Id/NoticeId/index";
import TestBox from "../../../Test/test";
import ContentDialog from "./ContentDialog/ContentDialog";
const imageDir = "/_asset/images/works/thumb/";

const NoticeGrid = () => {
	console.log(DisplayData)
	const [categoryCount, setCategoryCount] = useState(0);
	const [isShow, setShow] = useState(0);
	const [selectedItem, setItem] = useState(0);
	// const images = [zz
	//   {
	//     category: 0,
	//     url: `${imageDir}1@2x.png`,
	//   },
	//   {
	//     category: 5,
	//     url: `${imageDir}5@2x.png`,
	//   },
	// ];

	const [images, setImages] = useState([]);

	useEffect(() => {
			// for (let i = 0; i < 26; i++) {
			//   setImages((prev) => [
			//     ...prev,
			//     {
			//       category: Math.floor(Math.random() * categoryCount),
			//       url: `${imageDir}/${i + 1}@2x.png`,
			//     },
			//   ]);
			// }
			setImages(DisplayData.list);
	}, [categoryCount]);

	return (
		<>
		<div className='NoticeGridMain'>
			<div className="experience__grid">
				<div className="experience__list" style={{ maxWidth: "1680px" }}>
					<div className='NoticeTitle'>
						Notice
					</div>
					<div className='NoticeContents'>
					{images.length > 0
						? images.map((e, i) => {
							return (
								<div key={i} className="experience__item bx active visible">
									<a className="item__link">
										<div className="item__frame">
											<a href={`/news/${e.id}`}>
												<div
													className="item__dimd"
													style={{
														// backgroundColor: "rgb(255, 64, 1)",
														animation:
															"360ms cubic-bezier(0.215, 0.61, 0.355, 1) 50ms 1 normal both running slideOutDown",
													}}>
														
													<img src={e.img} alt="thumbnail" />
													<div className='NoticeTextBox'>
														<div className='TextTitle'>
															{e.title}
														</div>
														<div className='TextSub'>
															{e.sub}
														</div>
														<div className='TextSort'>
															{e.sort}
														</div>
													</div>	
												</div>
											</a>
										</div>
									</a>
								</div>
							);
							})
						: images
						}
					
						</div>
				</div>
			</div>
			</div>
			<ContentDialog
				open={isShow}
				onClose={() => setShow(false)}
				selectedContent={selectedItem}
			/>
		</>
	);

	// console.log(images.filter((e) => e.category === category));
};

export default NoticeGrid;
