import React, { useState, useEffect, useMemo } from "react";
import "./Grid.scss";
import MenuTab from "../../../Home/WorksTab/MenuTab";
import ContentDialog from "./ContentDialog/ContentDialog";
import { GetThumbnail } from "./GetThumbnail/GetThumbnail";
import { addAllVideo } from "src/Store/slice/videoDataSlice";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import Book from "src/_asset/images/svg/book.svg";
import useDidMountEffect from "src/Util/useDidMountEffect";

const categoryMapWithVideo = {
    1: [1, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13, 14, 15, 17, 18, 19, 20, 21, 22, 24, 26, 27, 28],
    2: [0, 2],
    3: [],
    4: [16, 23, 25],
    5: [0, 1, 3, 4, 5, 8, 9, 11, 16, 17, 18, 21, 22, 24, 26, 27, 28],
    6: [],
};

const Grid = ({ onLoad }) => {
    const [categoryCount, setCategoryCount] = useState(0);
    const [category, setCategory] = useState(0);
    const [isShow, setShow] = useState(false);
    const [selectedItem, setItem] = useState(0);
    const [images, setImages] = useState([]);

    const LIMIT_AVEAGE = 15;
    const [limit, setLimit] = useState(0);
    const [posts, setPosts] = useState([]);
    const [isNoData, setNoData] = useState(false);
    const dispatch = useDispatch();
    const allVideo = useSelector((state) => state.videosData.data);

    const [isAllVideo, setIsAllVideo] = useState(false);

    const countWorks = useMemo(() => {
        if (category === 0) {
            return allVideo.length;
        } else {
            const cnt = allVideo.filter((_, i) => categoryMapWithVideo[category]?.includes(i)).length;
            if (cnt === 0) {
                setNoData(true);
            }
            return cnt;
        }
    }, [allVideo, category]);

    let page = 1;
    const fetchVideoAndAddToStore = async (page) => {
        let temp = [];
        try {
            const response = await fetch(`https://vimeo.com/api/v2/toastcanvas/videos.json?page=${page}`);
            const data = await response.json();
            if (data) {
                if (page === 1 && !data.length) {
                    setNoData(true);
                }
                if (data.length !== 0) {
                    temp = [...data];
                    const payload = [...data];
                    dispatch(addAllVideo(payload));
                }

                if (temp.length !== 0 ) {
                     fetchVideoAndAddToStore(page + 1);
                } else {
                    setIsAllVideo(true);
                }
            }
        } catch (error) {
            setIsAllVideo(true);
        } finally {
           
        }
    };

    useEffect(() => {
        fetchVideoAndAddToStore(page);
    }, []);

    useDidMountEffect(() => {
        onLoad();
    }, [isAllVideo]);

    // ** End of -> Get all video from channel

    const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop + 1 < document.documentElement.scrollHeight - 200) {
            return;
        }
        setLimit((preveState) => {
            return preveState > images.length ? preveState : preveState + LIMIT_AVEAGE;
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    useEffect(() => {
        if (limit - LIMIT_AVEAGE < images.length) {
            let data;
            data = images.slice(limit, limit + LIMIT_AVEAGE);
            setPosts([...posts, ...data]);
        }
    }, [images, limit]);

    const [height, setHeight] = useState();

    useEffect(() => {
        const elmnt = document.getElementById("Showreel2021");
        if (elmnt) {
            setHeight(elmnt.offsetHeight);
        }
    }, [limit]);

    useEffect(() => {
        if (categoryCount !== 0) {
            setImages(allVideo);
        }
    }, [categoryCount, isAllVideo]);

    const checkNoData = countWorks === 0 && isNoData;
    return (
        <div className="ex-main-grid">
            <div className="experience__grid">
                <MenuTab
                    category={category}
                    setCategory={setCategory}
                    setCategoryCount={setCategoryCount}
                    countWorks={countWorks}
                />
                <div className={classNames("experience__list", { "no-data": checkNoData })}>
                    {allVideo.length > 0 &&
                        (category === 0
                        ? allVideo.map((e, i) => {
                                return (
                                    <div key={i} className="experience__item bx active visible">
                                        <a className="item__link">
                                            <div className="item__frame">
                                                <div
                                                    className="item__dimd"
                                                    onClick={() => {
                                                        setItem(e);
                                                        setShow(true);
                                                    }}
                                                >
                                                    {e.thumbnail_large?.length > 1 ? (
                                                        <img src={e.thumbnail_large} alt="thumbnail" />
                                                    ) : (
                                                        e.videoNew !== undefined && (
                                                            <GetThumbnail
                                                                style={{ height: height }}
                                                                id={e.id}
                                                                url={e.videoNew}
                                                            />
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })
                            : images
                                .filter((e, i) => categoryMapWithVideo[category]?.includes(i))
                                .map((e, i) => {
                                    return (
                                        <div key={e.title} className="experience__item bx active visible">
                                            <a className="item__link">
                                                <div className="item__frame">
                                                    <div
                                                        className="item__dimd"
                                                        onClick={() => {
                                                            setItem(e);
                                                            setShow(true);
                                                        }}
                                                    >
                                                        {e.thumbnail_large.length > 1 ? (
                                                            <img src={e.thumbnail_large} alt="thumbnail" />
                                                        ) : (
                                                            e.videoNew !== undefined && (
                                                                <GetThumbnail id={e.id} url={e.videoNew} />
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    );
                                }))}
                    {checkNoData && (
                        <div className="no-data">
                            <img src={Book} alt="" />
                            <span className="top">No data</span>
                            <span className="sub">No data, please try again later</span>
                        </div>
                    )}
                </div>
            </div>

            {isShow && <ContentDialog setOpenPopup={() => setShow(false)} data={selectedItem} />}
        </div>
    );
};

export default Grid;
