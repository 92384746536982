import React, { useState, useEffect } from "react";
import "./style/Gdesign.scss";
import styled from "styled-components";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import designtit from "../../../../_asset/images/g9/img_txt_section02_1.png";
import designimg from "../../../../_asset/images/g9/img_propose_a_device.png";
import botimage from "../../../../_asset/images/g9/img_propose_a_01.png";
import designmp4 from "../../../../_asset/images/g9/01_propose_a.mp4";
import designimg01 from "../../../../_asset/images/g9/img_propose_a_02.png";
import device from "../../../../_asset/images/g9/img_propose_b_device.png";

import listimg01 from "../../../../_asset/images/g9/img_propose_b_01.png";
import listimg02 from "../../../../_asset/images/g9/img_propose_b_02.png";
import listimg03 from "../../../../_asset/images/g9/img_propose_b_03.png";
import listvideo01 from "../../../../_asset/images/g9/02_propose_b.mp4";

import slide01 from "../../../../_asset/images/g9/sliderimg/img_item_01.png";
import slide02 from "../../../../_asset/images/g9/sliderimg/img_item_02.png";
import slide03 from "../../../../_asset/images/g9/sliderimg/img_item_03.png";
import slide04 from "../../../../_asset/images/g9/sliderimg/img_item_04.png";
import slide05 from "../../../../_asset/images/g9/sliderimg/img_item_05.png";
import slide06 from "../../../../_asset/images/g9/sliderimg/img_item_06.png";
import slide07 from "../../../../_asset/images/g9/sliderimg/img_item_07.png";
import slide08 from "../../../../_asset/images/g9/sliderimg/img_item_08.png";
import slide09 from "../../../../_asset/images/g9/sliderimg/img_item_09.png";
import slide10 from "../../../../_asset/images/g9/sliderimg/img_item_10.png";
import slide11 from "../../../../_asset/images/g9/sliderimg/img_item_11.png";

const setting = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 2000,
  cssEase: "linear",
};

const TitleImage = styled.div`
  width: 415px;
  height: 340px;
  margin-left: -12px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
  > img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`;

const Desctxt = styled.div`
  margin-top: 30px;
  color: #555;
  font-size: 14px;
  line-height: 28px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Text = styled.div`
  position: absolute;
  top: 112px;
  left: 387px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
`;

const LeftBox = styled.div`
  float: left;
  width: 472px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.4s linear;
  transition: transform 1.4s linear;
`;

const RightBox = styled.div`
  float: left;
  width: 472px;
  margin: 411px 0 32px -85px;
  padding-bottom: 60px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1.6s linear;
  transition: transform 1.6s linear;
`;

const ProposeB = styled.div`
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
`;

const ImgList01 = styled.div`
  display: inline-block;
  position: relative;
  width: 262px;
  height: 466px;
  margin-right: 50px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 2s linear;
  transition: transform 2s linear;
  > img {
    width: 100%;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
  }
`;
const ImgList02 = styled.div`
  display: inline-block;
  position: relative;
  width: 262px;
  height: 466px;
  margin-right: 50px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 60px, 0px)"};
  transition: opacity 2.2s linear;
  transition: transform 2.2s linear;
  > img {
    width: 100%;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
  }
`;
const ImgList03 = styled.div`
  display: inline-block;
  position: relative;
  width: 262px;
  height: 466px;
  margin-right: 50px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 70px, 0px)"};
  transition: opacity 2.4s linear;
  transition: transform 2.4s linear;
  > img {
    width: 100%;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
  }
`;
const ImgList04 = styled.div`
  display: inline-block;
  position: relative;
  width: 262px;
  height: 466px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 2.6s linear;
  transition: transform 2.6s linear;
  > video {
    width: 100%;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.02),
      60px 60px 50px rgba(0, 0, 0, 0.1);
  }
`;

const Gdesign = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);
  useEffect(() => {
    if (pageY >= 2700) {
      setTrigger(true);
    }
  });
  return (
    <div className="Gdesigncontainer">
      <div className="desc_propose">
        <TitleImage trigger={trigger}>
          <img src={designtit} alt="design_tit" />
        </TitleImage>
        <Desctxt trigger={trigger}>
          우리는 앞서 만든 전략을 기반으로 G9의 새로운 모습을 다양하게
          제안하였습니다. <br></br>
          제안을 통해 G9가 변화해 나갈 방향을 제시하고 이를 통해 사용자들에게
          새로운 <br></br>
          쇼핑 경험을 제공하고자 하였습니다.
        </Desctxt>
      </div>
      <div className="inner">
        <div className="conbox">
          <Text trigger={trigger}>
            <div className="tit">Propose. A</div>
            <div className="desc">
              A안 시안은 사용자 개개인에 맞춰진 콘텐츠 Feed를 무한으로
              제공함으로써 세로 <br></br>
              스크롤만으로 다양한 콘텐츠를 지속적으로 소비 할수 있도록 하는
              컨셉의 시안이였습니다 <br></br>
              개인화가 주된 콘셉으로 그 동안 옥션, G마켓을 통해 축적된 사용
              패턴을 기반으로 앱에 <br></br>
              접속할때 마다 그 사용자에 맞는 상품과 콘텐츠를 추천하고 지속적으로
              소비할 수 있게 <br></br>
              하고, 상품과 어울리는 파스텔톤을 썸네일 배경에 사용하여 경쾌한
              느낌의 G9이미지를 <br></br>
              만들고자 하였습니다.
            </div>
          </Text>
          <LeftBox trigger={trigger}>
            <div className="Top">
              <img src={designimg} alt="design_image01" />
            </div>
            <div className="Bot">
              <img src={botimage} alt="design_image02" />
            </div>
          </LeftBox>
          <RightBox trigger={trigger}>
            <div className="videoWrap">
              <video id="Design_video" loop autoPlay style={{ width: "100%" }}>
                <source src={designmp4} alt="Design_video" />
              </video>
            </div>
            <div className="imgWrap">
              <img src={designimg01} alt="design_image03" />
            </div>
          </RightBox>
        </div>
        <div className="Slide_Box">
          <div className="bg_circle"></div>
          <div className="device_box">
            <img src={device} alt="device_image" />
          </div>
          <div className="slide_area">
            <Slider {...setting}>
              <div className="slide_image_Wrap">
                <img src={slide01} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide02} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide03} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide04} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide05} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide06} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide07} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide08} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide09} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide10} alt="slide_image" />
              </div>
              <div className="slide_image_Wrap">
                <img src={slide11} alt="slide_image" />
              </div>
            </Slider>
          </div>
          <div className="BBox">
            <div className="BBox_inner">
              <ProposeB trigger={trigger}>
                <div className="BBox_tit">Propose. B</div>
                <div className="BBox_txt">
                  B안 시안은 첫 메인 화면에서 매일매일 사용자에 맞는 9개 상품을
                  가로로 추천해주고 <br></br>
                  하단으로는 그날의 핫딜 상품을 노출하여 개인화와 큐레이션을
                  중점으로 한 시안입니다. <br></br>
                  전반적으로 말랑말랑한 문구와 원형 아이덴티티를 사용하여 G9의
                  친근한 모습을 <br></br>
                  보여주고자 하였습니다.
                </div>
              </ProposeB>
              <div className="Image_List">
                <ImgList01 trigger={trigger}>
                  <img src={listimg01} alt="List_image01" />
                </ImgList01>
                <ImgList02 trigger={trigger}>
                  <img src={listimg02} alt="List_image02" />
                </ImgList02>
                <ImgList03 trigger={trigger}>
                  <img src={listimg03} alt="List_image03" />
                </ImgList03>
                <ImgList04 trigger={trigger}>
                  <video id="Propose_video" loop autoPlay>
                    <source src={listvideo01} alt="ProposeB" />
                  </video>
                </ImgList04>
              </div>
              {/* <div className="Propose_C">
                <div className="Propose_inner">
                  
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gdesign;
