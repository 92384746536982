import React, { Dispatch, useEffect, useRef, useState } from "react";
import "./ContentDialog.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router-dom";


const ContentDialog = ({ data, setOpenPopup }) => {
	const { id, company, mainImg, name, type, url, workerTag, detailImg, title } = {...data};

	const videoNew = url.replace("https://vimeo.com/","https://player.vimeo.com/video/");

	const [language, setLanguage] = useState("");
	const history = useHistory();
	useEffect(() => {
		const lang = sessionStorage.getItem("language");
		setLanguage(lang);
	}, [language]);

	return (
		<div className="popup">
			<div className="popup-container" style={{ position: "relative" }}>
				<div className="btn-close" onClick={setOpenPopup}>
					<CloseIcon></CloseIcon>
				</div>
				<div className="popup-inner">
					<div className="popup-header"></div>

					<div className="popup-contents">
						<div className="scroll-inners">
							<>
								{videoNew ? ( //case 1: vimeo is exist
									<div className="mainVideo">
										<iframe
											id="iMovie"
											title="mainVideo"
											name="movieFrame"
											src={videoNew}
											frameBorder="0"
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen={true}
										/>
									</div>
								) : null}
							</>

							{/* {Case2: detailImg exist and is Array} */}
							{detailImg && Array.isArray(detailImg)
								? detailImg.map((sp, index) => (
										<img
											style={{ width: "100%", height: "100%" }}
											key={index}
											src={sp.toString()}
											alt=""
										/>
								  ))
								: detailImg && ( //case 3 detailImg exist and is image
										<img
											style={{ width: "100%", height: "100%" }}
											src={detailImg}
											alt=""
										/>
								  )}
							{/* <div className="overView">
								<div className="overViewWrap">
									<div className="left-title">
										<div className="type">{type}</div>
										<div className="company">
											{language === "ko" ? company?.ko : company?.en}
										</div>
									</div>
								</div>
							</div> */}
							<div className="overView">
								<div className="overViewWrap">
									<div className="left-title">
										<div className="type">{type}</div>
										<div className="company">
											{title}
										</div>
									</div>
								</div>
							</div>

							<div className="contact-works">
								<a href="mailto:loocreative@playlooc.com" title="">
									Contact us
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContentDialog;
