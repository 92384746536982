import React from "react";
import Sketch from "react-p5";
import "./CrossGrid.scss";

class CrossGrid extends React.PureComponent {
    canvas;
    cam;

    angleX = 66.0;
    angleY = -15.0;
    angleZ = 45.0;
    speed = 1;
    dist = 10;
    isClick = false;
    duration = 0;

    size = 180;
    length = 640;
    length2 = 340;
    count = 36;

    prevX;
    prevY;

    startPositionZ = -500;
    startPositionY = 30;
    startPositionX = 500;
    testSize = 1.6;

    setup = (p5, canvasParentRef) => {
        this.canvas = p5.createCanvas(window.innerWidth, window.innerHeight, p5.WEBGL).parent(canvasParentRef);
        this.cam = p5.createCamera();
        this.cam.setPosition(0, 0, 2500);
        p5.noFill();

        // p5.rectMode(p5.RADIUS);
        p5.angleMode(p5.DEGREES);
    };

    draw = (p5) => {
        p5.noLoop();
        p5.background("#e7304d");
        p5.strokeWeight(4);
        p5.stroke(255, 255, 255, 1);

        this.angleZ = p5.lerp(this.angleZ, 1 * this.duration, 0.2);

        if (this.angleZ + 1 >= 1 * this.duration) {
            this.duration += 10;
            if (this.duration >= 360) {
                this.duration = 0;
                this.angleZ = 0;
            }
        }

        p5.rotateX(this.angleX);
        p5.rotateY(this.angleY);
        p5.rotateZ(this.angleZ);

        // p5.translate(0, 0, -this.count * 0.5 * this.dist);

        // for (let loop = 0; loop < this.count; loop++) {
        //     this.renderCross(p5);
        //     p5.translate(0, 0, this.dist);
        // }

        p5.translate(-100, -400, -this.count * 0.5 * this.dist);

        for (let loop = 0; loop < this.count; loop++) {
            this.renderCross(p5);
            p5.translate(0, 0, this.dist);
        }

        //다른 애니메이션

        // this.startPositionY = -50;
        // this.startPositionX = -500;
        // this.startPositionZ = 0;
        // this.testSize = 2;
        // this.count = 1;
        // p5.translate(this.startPositionX, this.startPositionY, 0);
        // for (let loop = 0; loop < this.count; loop++) {
        //     this.startPositionX = -220;
        //     this.renderT(p5, this.startPositionX, this.startPositionY, this.testSize);
        //     this.startPositionX += 100;
        //     this.renderO(p5, this.startPositionX, this.startPositionY, this.testSize);
        //     this.startPositionX += 100;
        //     this.renderA(p5, this.startPositionX, this.startPositionY, this.testSize);
        //     this.startPositionX += 100;
        //     this.renderS(p5, this.startPositionX, this.startPositionY, this.testSize);
        //     this.startPositionX += 100;
        //     this.renderT(p5, this.startPositionX, this.startPositionY, this.testSize);
        //     p5.translate(0, 0, loop * 10);
        // }
    };

    renderCross(p5) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape();
        p5.vertex(-90 * this.testSize, 90 * this.testSize);
        p5.vertex(-90 * this.testSize, -730 * this.testSize);
        p5.vertex(-360 * this.testSize, -730 * this.testSize);
        p5.vertex(-360 * this.testSize, (-730 - this.size) * this.testSize);
        // p5.vertex(-360, -730 - this.size);
        p5.vertex(360 * this.testSize, (-730 - this.size) * this.testSize);
        p5.vertex(360 * this.testSize, -730 * this.testSize);
        p5.vertex(90 * this.testSize, -730 * this.testSize);
        p5.vertex(90 * this.testSize, 90 * this.testSize);
        p5.endShape(p5.CLOSE);
        p5.rotate(-10);
    }
    renderT(p5, startPositionX = 0, startPositionY = 0, size = 2) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape();
        p5.vertex((0 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (20 + startPositionY) * size);
        p5.endShape(p5.CLOSE);
    }
    renderO(p5, startPositionX = 0, startPositionY = 0, size = 2) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape();
        p5.vertex((0 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (60 + startPositionY) * size);
        p5.endShape(p5.CLOSE);
        p5.beginShape();
        p5.vertex((20 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (40 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (40 + startPositionY) * size);
        p5.endShape(p5.CLOSE);
    }
    renderA(p5, startPositionX = 0, startPositionY = 0, size = 2) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape();
        p5.vertex((0 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (40 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (40 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (60 + startPositionY) * size);
        p5.endShape(p5.CLOSE);
        p5.beginShape();
        p5.vertex((20 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (30 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (30 + startPositionY) * size);
        p5.endShape(p5.CLOSE);
    }
    renderS(p5, startPositionX = 0, startPositionY = 0, size = 2) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape();
        p5.vertex((0 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (0 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (20 + startPositionY) * size);
        p5.vertex((20 + startPositionX) * size, (25 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (25 + startPositionY) * size);
        p5.vertex((60 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (60 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (40 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (40 + startPositionY) * size);
        p5.vertex((40 + startPositionX) * size, (35 + startPositionY) * size);
        p5.vertex((0 + startPositionX) * size, (35 + startPositionY) * size);

        p5.endShape(p5.CLOSE);
    }

    renderC(p5, startPositionX = 0, startPositionY = 0, size = 2) {
        p5.stroke("#000");
        p5.noFill();
        p5.beginShape(p5.TESS);
        p5.vertex(0 + startPositionX, 0);
        p5.vertex(60 + startPositionX, 0);
        p5.vertex(60 + startPositionX, 20);
        p5.vertex(20 + startPositionX, 20);
        p5.vertex(20 + startPositionX, 40);
        p5.vertex(60 + startPositionX, 40);
        p5.vertex(60 + startPositionX, 60);
        p5.vertex(0 + startPositionX, 60);
        p5.endShape(p5.CLOSE);
    }

    renderN(p5, startPositionX, startPOsitionY) {}
    renderV(p5, startPositionX, startPOsitionY) {}

    mousePressed = (p5) => {
        p5.loop();
        this.prevX = p5.mouseX;
        this.prevY = p5.mouseY;
        // console.log(clickX + ", " + clickY);
    };

    mouseDragged = (p5) => {
        p5.loop();
        let dx = this.prevX - p5.mouseX;
        let dy = this.prevY - p5.mouseY;

        // console.log((dx*0.01)+", "+(dy));
        this.angleX = p5.lerp(this.angleX, this.angleX + dy * 0.5, 0.7);
        this.angleY = p5.lerp(this.angleY, this.angleY + dx * 0.5, 0.7);

        this.prevX = p5.mouseX;
        this.prevY = p5.mouseY;
    };

    render() {
        return (
            <Sketch
                windowResized={this.windowResized}
                style={{
                    position: "fixed",
                    width: "100%",
                    height: "100%",
                    color: "#FFF",
                }}
                setup={this.setup}
                draw={this.draw}
                mousePressed={this.mousePressed}
                mouseDragged={this.mouseDragged}
            />
        );
    }
}

export default CrossGrid;
