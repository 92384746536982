import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import "./MenuTab.scss";
import ScrollContainer from 'react-indiana-drag-scroll'

const MenuTab = ({
	category,
	setCategory,
	setCategoryCount,
	countWorks,
	location,
}) => {
	const tabs = [
		"All",
		"Advertisement",
		"Music Video",
		"Motion",
		"Title",
		"Artwork",
		"Etc",
	];


	useEffect(() => {
		setCategoryCount(tabs.length);
		const queryData = queryString.parse(location.search);
		const id = tabs.indexOf(queryData.id);
		if (id !== -1) {
			setCategory(id);
		}
	}, []);

	return (
			<div className="scroll-wrapper">
			<ScrollContainer className="scroll-container">
				<div className="MenuTabContainer" id="MenuTabContainer">
						<div className="MenuTabMain" id="MenuTabMain">
							{tabs.map((e, i) => {
								return (
									<div
										key={JSON.stringify(e)}
										className={
											category === i ? "MenuTabCategoryHover" : "MenuTabCategory"
										}
										style={{ cursor: "pointer" }}
										onClick={() => {
											setCategory(i);
										}}>
										<div className="NumberCircle">
											<a>{countWorks}</a>
										</div>
										<span className="MenuLTabText">
											<span className="mask-text" style={{ animationDelay: "0ms" }}>
												<span>{e}</span>
											</span>
										</span>
									</div>
								);
							})}
						</div>
				</div>
			</ScrollContainer>
			</div>

	);
};

export default withRouter(MenuTab);
