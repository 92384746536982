//Data Import
import news1 from '../../Data/NoticeData/News1/News1'

//Image Import
import test1 from "../../Data/NoticeData/Image/notice-img-01@2x.png";
import test2 from "../../Data/NoticeData/Image/notice-img-02@2x.png";
import test3 from "../../Data/NoticeData/Image/notice-img-03@2x.png";
import test4 from "../../Data/NoticeData/Image/notice-img-04@2x.png";
import test5 from "../../Data/NoticeData/Image/notice-img-05@2x.png";
import test6 from "../../Data/NoticeData/Image/notice-img-06@2x.png";

export default {
	list: [
		{
			id: "News1",
			img: test1,
			contents: news1,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test1",
			img: test1,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Notice"
		},
		{
			id: "test2",
			img: test2,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Notice"
		},
		{
			id: "test3",
			img: test3,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test4",
			img: test4,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test5",
			img: test5,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test6",
			img: test6,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test7",
			img: test1,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test8",
			img: test2,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test9",
			img: test3,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
		{
			id: "test10",
			img: test4,
			title: "TDC Member of The Month",
			sub: "An interview between Elizabeth Carey Smith and Kris Sowersby for the TDC Member of The Month series.",
			sort: "Interviews"
		},
	],
};
