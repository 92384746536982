import React, { Component } from 'react';
import News from '../../Home/News/News'
import HeaderWhite from "../../Common/HeaderWhite/HeaderWhite";
import FooterMainWhite from "../../Common/FooterMainWhite/FooterMainWhite";

class RouterNews extends Component {
    
    render() {
        return (
            <div>
                <HeaderWhite/>
                <News match={this.props.match}/>
                <FooterMainWhite/>
            </div>
        );
    }
}

export default RouterNews;