import React, { Component } from "react";
import Header from "../../Common/Header/Header";
import MainLinkBox from "../../Home/MainLinkbox/MainLinkBox";
import FooterNav from "../../Common/FooterNav/FooterNav";
import Canvas from "../../Common/Canvas/Canvas";
import Control from "../../Common/Control/Control";
import ReactFullpage from "@fullpage/react-fullpage";
class MainRouter extends Component {
    state = {
        currentPage: 1,
        isLogoBlack: false,
    };

    getPageNum = (history) => {
        if (history === "#firstpage") {
            return 1;
        } else if (history === "#secondpage") {
            return 2;
        } else if (history === "#thirdpage") {
            return 3;
        } else if (history === "#fourpage") {
            return 4;
        } else if (history === "#fivepage") {
            return 5;
        } else {
            return 1;
        }
    };

    render() {
        return (
            <div>
                {/* <Canvas></Canvas> */}

                <Header isLogoBlack={this.props.history.location.hash === "#firstpage" ? true : this.props.history.location.hash === "" ? true : false}></Header>
                <MainLinkBox handleHeaderChange={this.handleHeaderChange} history={this.props.history} isPaginationBlack={this.state.isLogoBlack}></MainLinkBox>
            </div>
        );
    }
}

export default MainRouter;
