import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	data: []
};
const videosData = createSlice({
	name: "thumbnail",
	initialState,
	reducers: {
		addAllVideo(state, action) {
            
            state.data = [
                ...state.data,
                ...action.payload
			];
		}
	}
});

export const { addAllVideo } = videosData.actions;
export default videosData.reducer;
