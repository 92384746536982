import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./style/brand.scss";
import branding from "../../../../_asset/images/29TV/img_brand01.png";
import branding02 from "../../../../_asset/images/29TV/img_brand02.png";
import listimg01 from "../../../../_asset/images/29TV/img_select01.jpg";
import listimg02 from "../../../../_asset/images/29TV/img_select02.jpg";
import listimg03 from "../../../../_asset/images/29TV/img_select03.jpg";
const Title = styled.div`
  font-size: 55px;
  line-height: 1.7;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  color: #fff;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 1s linear;
`;

const Ullist = styled.div`
  display: flex;
  margin-top: 146px;
  margin-left: 183px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 50px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
`;

const TitleTwo = styled.div`
  font-size: 55px;
  text-align: left;
  letter-spacing: 25px;
  font-weight: 700;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
`;

const Subtitletwo = styled.div`
  font-size: 16px;
  margin-top: 50px;
  line-height: 2;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 80px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2s linear;
`;

const ImgWrap = styled.div`
  position: relative;
  left: 50%;
  height: 1220px;
  margin-left: 35px;
  opacity: ${(props) => (props.trigger ? "1" : "0")};
  transform: ${(props) =>
    props.trigger
      ? "translate3d(0px, 0px, 0px)"
      : "translate3d(0px, 100px, 0px)"};
  transition: opacity 1s linear;
  transition: transform 2.2s linear;
`;

const Brand = ({ pageY }) => {
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    if (pageY >= 21100) {
      setTrigger(true);
    }
  }, [pageY]);

  return (
    <div className="brandcontainer">
      <div className="cnt_top">
        <Title trigger={trigger}>
          BRAND <br />
          COMMUCICATION <br />
          AND <br />
          SHOPPING
        </Title>
        <Ullist trigger={trigger}>
          <div className="brand_img_01">
            <img src={branding} alt="brand_image" />
          </div>
          <div className="brand_img_02">
            <img src={branding02} alt="brand_image02" />
          </div>
        </Ullist>
      </div>
      <div className="cnt">
        <TitleTwo trigger={trigger}>
          SELECTED <br />
          29CC
        </TitleTwo>
        <Subtitletwo trigger={trigger}>
          유저들이 브랜드를 어떻게 생각하는지, 어떤 태도로 브랜드를 대하는지
          <br />
          29TV 콘텐츠를 통해 자연스럽게 표현됩니다.
          <br />
          브랜드는 선택된 유저와 협업을 시도할 수 있습니다.
        </Subtitletwo>
        <ImgWrap trigger={trigger}>
          <div className="list_img">
            <img src={listimg01} alt="select01" />
          </div>
          <div className="list_img02">
            <img src={listimg02} alt="select02" />
          </div>
          <div className="list_img03">
            <img src={listimg03} alt="select03" />
          </div>
        </ImgWrap>
      </div>
    </div>
  );
};

export default Brand;
