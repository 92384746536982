import React, { Component } from "react";
import "./Style/FifthPage.scss";
import "../../Common/LinkClick/LinkClick.scss";

class FifthPage extends Component {
	render() {
		return (
			<div
				className="FifthPage"
				style={{ width: "100%", height: "100%", color: "#FFF" }}
			>
				<div className='Link'>
					<div className='LinkClickContainer'>
						<div className='LinkClickText'>
							<a href='/contact'>Click</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default FifthPage;
