import React, { Component } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import Pages from "./Router/index";
import FooterNav from "./Common/FooterNav/FooterNav";
import HamburgerMenu from "./Common/HamburgerMenu/HamburgerMenu";
import MainLinkBox from "./Home/MainLinkbox/MainLinkBox";
import ArtWork from "./Home/ArtWork/ArtWork";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* <Route component={Navigation} path={"/"}></Route> */}
        {Pages().map(({ exact, component, path }) => {
          return (
            <Route
              key={path}
              exact={exact}
              component={component}
              path={path}
              {...this.props}
            ></Route>
          );
        })}
      </BrowserRouter>
    );
  }
}

export default App;
