import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addThumbnail } from "./thumbnailSlice";


export function GetThumbnail({ id, url, height }) {

	const [thumb, setThumb] = useState(url);

	const [result, setResult] = useState("")
	const dispatch = useDispatch();
	const thumbnailList = useSelector(state => state.thumbnail.thumbnailList);
	
	function getVimeoThumbnail(url) {
		if (url) {
			let video_id;
			let thumbnail;
			console.log("url:::", url)

			if (url.match(/https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/)) {
				video_id = url.split("/")[3];
			} else if (url.match(/^vimeo.com\/channels\/[\d\w]+#[0-9]+/)) {
				video_id = url.split("#")[1];
			} else if (url.match(/vimeo.com\/groups\/[\d\w]+\/videos\/[0-9]+/)) {
				video_id = url.split("/")[4];
			} else if (url.match(/player.vimeo.com\/video\/[0-9]+/)) {
				video_id = url.split("/")[4];
			}
			return new Promise((resolve, reject) => {
				fetch("https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/" + video_id).then(
					response => {
						response
							.json()
							.then(jsonData => {
								const res = jsonData.thumbnail_url.split("_")[1];
								thumbnail = jsonData.thumbnail_url.replace(res, "1280x720");
								resolve(thumbnail);
							})
							.catch(error => {
								console.error(error);
								reject(false);
							});
					}
				);
			});
		}
	}
	
	const generalThumb = async (thumb) => {
		const thumbnail = await getVimeoThumbnail(thumb);
		setResult(thumbnail);
		const action = addThumbnail({
			id: id,
			value: thumbnail
		});
		dispatch(action);
	};

	useEffect(() => {
		const item = thumbnailList.find((item) => item.id === id);

		if (item) {
			setResult(item.value);
		} else {
			generalThumb(thumb);
		}
	}, []);




	if (!result) {
		return <Skeleton width="100%" height={height ? height : 200} />;
	}

	return (
		<>
			<img
				id={id}
				key={"image_" + result}
				src={result}
				alt="thumbnail"
			/>
		
			
		</>
	);
}
