import { gsap } from "gsap";
import React from "react";
import feGaussianBlur from "react-gaussian-blur";
import "./MainBack4.scss";

export default class MenuItem extends React.Component {
	state = {
		textsGroupElFilter: { filter: "none" }, //텍스트 스타일 (goo-1 or none)
		primitiveValues: { stdDeviation: 0 }, //가우시안블러 표준편차
	};

	primitiveValues = { stdDeviation: 0 };

	componentDidMount() {
		// console.log(this.props)

		this.setState({ primitiveValues: { stdDeviation: 0 } });

		this.createTimeline();
	}

	createTimeline() {
		// init timeline
		this.tl = gsap
			.timeline({
				paused: true, //타임라인 생성과 동시에 일시정지한 상태
				onComplete: () => {
					this.setState({
						textsGroupElFilter: { filter: "none" },
					});
				},
				onReverseComplete: () => {
					this.setState({
						textsGroupElFilter: { filter: "none" },
					});
				},
				onUpdate: () => {
					// console.log(this.primitiveValues);
					this.setState({
						primitiveValues: {
							stdDeviation: this.primitiveValues.stdDeviation,
						},
					});
				},
			})

			//블러 표준편차값 변화(0 -> 1 -> 0)
			.to(
				this.primitiveValues,
				{
					duration: 0.9,
					ease: "tween", //tween 값 변화곡선 none : 일정하게 변함
					startAt: { stdDeviation: 0 },
					stdDeviation: 1,
				},
				0
			)
			.to(this.primitiveValues, {
				duration: 0.9,
				ease: "tween",
				stdDeviation: 0,
			})

			//텍스트 opacity변화
			.to(
				`#${this.props.filterId}_text_1`,
				{
					duration: 1.8,
					ease: "tween", //
					opacity: 0,
				},
				0
			)
			.to(
				`#${this.props.filterId}_text_2`,
				{
					duration: 1.8,
					ease: "tween", //
					opacity: 1,
				},
				0
			);
	}

	handleMouseEnter = () => {
		this.setState({
			textsGroupElFilter: { filter: `url(#${this.props.filterId})` },
		});
		this.tl.play();
	};

	handleMouseLeave = () => {
		this.setState({
			textsGroupElFilter: { filter: `url(#${this.props.filterId})` },
		});
		this.tl.reverse();
	};

	toDetailWork = (id) => {
		this.props.history.push(`/Works?id=${id}`);
	};

	render() {
		const { filterId, text_1, text_2 } = this.props;
		const { textsGroupElFilter, primitiveValues } = this.state;
		return (
			<div
				className="menu__item"
				onMouseEnter={this.handleMouseEnter}
				onMouseLeave={this.handleMouseLeave}
				onClick={() => this.toDetailWork(text_1)}>
				<svg
					className="menu__text"
					viewBox="0 0 110 20"
					preserveAspectRatio="xMinYMid meet">
					<defs>
						<filter className="menu__single" id={filterId}>
							<feGaussianBlur
								in="SourceGraphic"
								stdDeviation={primitiveValues.stdDeviation}
								result="blur"></feGaussianBlur>
							<feColorMatrix
								in="blur"
								mode="matrix"
								values="	1 0 0 0 0  
																			0 1 0 0 0  
																			1 0 1 0 0  
																			0 0 0 17 -7"
								result="goo"></feColorMatrix>
							<feComposite
								in="SourceGraphic"
								in2="goo"
								operator="atop"></feComposite>
						</filter>
					</defs>
					<g style={textsGroupElFilter}>
						<text id={`${filterId}_text_1`} x="0" y="15">
							{text_1}
						</text>
						<text id={`${filterId}_text_2`} x="0" y="15">
							{text_2}
						</text>
					</g>
				</svg>
			</div>
		);
	}
}
