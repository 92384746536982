import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	thumbnailList: []
};
const thumbnailSlice = createSlice({
	name: "thumbnail",
	initialState,
	reducers: {
		
		addThumbnail(state, action) {
            
            const newItem = action.payload;
			const index = state.thumbnailList.find(x => x.id === newItem.id);
			if (!index) {
                state.thumbnailList.push(newItem);
			}
		}
	}
});

export const { addThumbnail } = thumbnailSlice.actions;
export default thumbnailSlice.reducer;
